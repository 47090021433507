import React, { Component } from 'react';
import './App.scss';
import InfoBox from "./components/InfoBox";
import Footer from "./components/Footer";

class App extends Component {
    render() {
        return (
            <div id={"App"}>
                {/*<Header/>*/}
                <InfoBox/>



                <Footer/>
            </div>
        );
    }
}

export default App;
