import React, {Component} from 'react';

class Footer extends Component {
    render() {
        return (
            <div id={"footer"}>
                <h4 id={"footer-text"}>Status page for EntertainmentHub</h4>
            </div>
        );
    }
}

export default Footer;