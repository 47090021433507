import React, {Component} from 'react';

const IMG_MISSING_URL = "https://i.ibb.co/vzg7qF5/pulse.png";

class HealthCheckItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hcName: "Unnamed",
            hcStatus: false,
            hcDesc: "No description",
            hcImgUrl: IMG_MISSING_URL,
            timeSinceLastPing: "",
            timeToNextPing: "",

        }

        this.setDateObjects = this.setDateObjects.bind(this)
        this.getTimeDif = this.getTimeDif.bind(this)
        this.isUp = this.isUp.bind(this)
        this.initComponent = this.initComponent.bind(this)

    }

    componentDidMount() {
        this.initComponent()
    }

    initComponent () {
        const {itemValues} = this.props


        if (itemValues.tags.length > 0) {
            this.setState(() => ({
                    hcImgUrl: itemValues.tags
                }
            ))
        }

        if (itemValues.name.length > 0){
            this.setState(() => ({
                    hcName: itemValues.name
                }
            ))
        }

        this.setState(() => ({
                hcStatus: this.isUp(itemValues.status),
            }
        ))

        if (itemValues.last_ping != null) {
            this.setDateObjects(itemValues.last_ping)
        } else {
            this.setState(() => ({
                    timeSinceLastPing: "Not pinged",
                    timeToNextPing: "Not pinged",
                }
            ))
        }

        if (itemValues.desc.length > 0) {
            this.setState(() => ({
                    hcDesc: itemValues.desc,
                }
            ))
        }

    }

    setDateObjects (lastPing_string) {
        const {itemValues} = this.props

        let today = new Date()
        let lastPing = new Date(lastPing_string)
        let nextPing = new Date(lastPing.getTime() + (itemValues.timeout/60)*60000);


        let timeSinceLastPing = this.getTimeDif(lastPing, today)
        let timeToNextPing = this.getTimeDif(nextPing, today)

        this.setState(() => ({
                timeSinceLastPing: timeSinceLastPing,
                timeToNextPing: timeToNextPing
            }
        ))
    }


    getTimeDif (date_from, date_to) {

        let diffTime = date_from.getTime() - date_to.getTime()
        let diffDates = date_from - date_to

        let diffDays = parseInt(diffDates / (1000 * 60 * 60 * 24));
        let diffHrs = parseInt(Math.abs(diffDates) / (1000 * 60 * 60) % 24);
        let diffMins = parseInt(Math.abs(diffTime) / (1000 * 60) % 60);
        let diffSec = parseInt(Math.abs(diffTime) / (1000) % 60);

        if (diffDays > 0) {
            return (diffDays + " day(s), " + diffHrs + " hour(s) ago")
        } else if (diffHrs > 0) {
            return (diffHrs + " hour(s), " + diffMins + " minute(s) ago")
        } else if (diffMins > 1) {
            return (diffMins + " minute(s) ago")
        } else if (diffSec < 3) {
            return ("now")
        } else if (diffSec < 5) {
            return ("few seconds ago")
        } else if (diffSec > 5) {
            return (diffSec + " seconds ago")
        }

    }

    isUp (status) {

        if (status === "down") {
            return false
        } else if (status === "up") {
            return true
        } else if (status === "new") {
            return null
        }
    }

    render() {
        const {hcName, hcStatus, hcImgUrl, hcDesc, timeSinceLastPing, timeToNextPing} = this.state
        return (

            <div className={"healtcheck-box flip"}>

                <div className={"front"}>

                    <div className={"healthcheck-content"}>
                        <h1 className={"healthcheck-title"}>
                            {hcName}
                        </h1>

                        <h3 className={"healthcheck-last-ping-title"}>
                            Last updated:
                        </h3>

                        <h3 className={"healthcheck-last-ping-value"}>
                            {timeSinceLastPing}.
                        </h3>

                        <h3 className={"healthcheck-last-ping-title"}>
                            Next update:
                        </h3>

                        <h3 className={"healthcheck-last-ping-value"}>
                            {timeToNextPing}.
                        </h3>

                        <div className={"healthcheck-status-container"}>

                            <h3 className={"healthcheck-status"}>
                                Service is {hcStatus ? "up" : "down"}
                            </h3>
                        </div>
                    </div>
                    <img alt={hcName + "_logo"} className={"healthcheck-img"} src={hcImgUrl} />

                    <div className={"circle-wrapper"}>

                        <div className={`circle--sm circle-status${
                            hcStatus ? "--up" : "--down"
                            }`} />

                        <div className={`circle--md circle-status${
                            hcStatus ? "--up" : "--down"
                            }`} />

                        <div className={`circle--xl circle-status${
                            hcStatus ? "--up" : "--down"
                            }`} />
                    </div>

                </div>

                <div className={"back"}>
                    <div className={"healthcheck-content"}>
                        <img alt={hcName + "_logo"} className={"healthcheck-img"} src={hcImgUrl} />

                        <h1 className={"healthcheck-title"}>
                            Description
                        </h1>

                        <h3 className={"healthcheck-description"}>
                            {hcDesc}
                        </h3>
                    </div>
                </div>

            </div>

        );
    }
}

export default HealthCheckItem;
