import React, {Component} from 'react';

class TotalStatus extends Component {
    constructor(props) {
        super(props);

        this.renderServiceStatus = this.renderServiceStatus.bind(this)
    }


    renderServiceStatus () {
        const {totalServices, servicesIsUp, servicesIsDown} = this.props

        let infoMsg = ""

        if(servicesIsDown === 0) {
            infoMsg = <p className={"service-info"}>All <h4 className={"service-number"}>{totalServices}</h4> services are running.</p>
        } else if ((totalServices - servicesIsDown) === 0) {
            infoMsg = <p className={"service-info"}>All <h4 className={"service-number"}>{totalServices}</h4> services are down!</p>
        } else {
            infoMsg = <p className={"service-info"}><h4 className={"service-number"}>{servicesIsUp}</h4> services are up, <h4 className={"service-number"}>{servicesIsDown}</h4> is down.</p>
        }

        return (
            <div id={"service-bar"}>
                {/*<h1 id={"service-title"}>Overall status:</h1>*/}{infoMsg}
            </div>
        )

    }

    render() {
        return (
            <div>
                {this.renderServiceStatus()}
            </div>
        );
    }
}

export default TotalStatus;