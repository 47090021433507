import React, {Component} from 'react';
import HealthCheckItem from "./HealthCheckItem";
import TotalStatus from "./TotalStatus";
const axios = require('axios');


class InfoBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            blackList: ["tsm121.com", "NZBget", "Transmission"],
            totalServices: 0,
            servicesIsDown : 0,
            servicesIsUp: 0,
            healthChecks : [{"name": "HC Name", "ping_url": "https://hc-ping.com/#", "update_url": "https://healthchecks.io/api/v1/checks/#", "pause_url": "https://healthchecks.io/api/v1/checks/#/pause", "tags": "https://banner2.kisspng.com/20180505/avw/kisspng-plex-media-server-computer-icons-media-player-tv-icon-5aed5cb0eb6725.0850667015255052009642.jpg", "grace": 1200, "n_pings": 44, "status": "up", "channels": "", "last_ping": "2019-04-14T16:00:24+00:00", "next_ping": null, "desc": "", "timeout": 3600}]
        }
        this.fetchData = this.fetchData.bind(this)
        this.savePayload = this.savePayload.bind(this)
        this.checkTotalStatus = this.checkTotalStatus.bind(this)
    }

    componentWillMount() {
        this.savePayload()
    }

    savePayload () {
        let test = this.fetchData().then(data =>(
            this.setState(() => (
                {healthChecks: data}
            )))
        ).then( () => {
            console.log(this.state.healthChecks)
            this.checkTotalStatus()
        })
    }


    fetchData () {
        let config = {
            headers: {
                "X-Api-Key": "PDF1m1iTIjimTM7WXKh90HEkTvSiWmNo",
            }
        }

        let payload = axios.get('https://healthchecks.io/api/v1/checks/',config)
            .then(function (response) {
                // handle success
                //console.log(response);
                return (JSON.parse(response["request"]["response"])["checks"])
            })
            .catch(function (error) {
                // handle error
                //console.log(error);
            })

        return payload
    }

    checkTotalStatus () {
        const {healthChecks, blackList} = this.state

        let counter = 0
        let isDown = 0
        let isUp = 0
        for (let i in healthChecks) {
            if (!blackList.includes(healthChecks[i].name)){
                counter += 1
                if (healthChecks[i].status === "up"){
                    isUp += 1
                } else if (healthChecks[i].status === "down" || healthChecks[i].status === "new"){
                    isDown += 1
                }
            }
        }
        this.setState(() => ({
                totalServices: counter,
                servicesIsDown : isDown,
                servicesIsUp: isUp,
            }
        ))
    }

    render() {
        const {healthChecks, totalServices, servicesIsDown, servicesIsUp } = this.state

        return (

            <div>
                <div id={"total-status-container"}>
                    <TotalStatus
                        totalServices={totalServices}
                        servicesIsDown={servicesIsDown}
                        servicesIsUp={servicesIsUp}
                    />
                </div>

                <div id={"wrapper"}>
                    <div id={"info-container"}>
                        {healthChecks.map( (values) =>
                            {if(!this.state.blackList.includes(values.name)) { return (
                                <HealthCheckItem
                                    itemValues={values}
                                    key={"id_" + values.name}
                                />
                            )} return ''}
                        )}
                    </div>
                </div>

            </div>
        );
    }
}

export default InfoBox;
